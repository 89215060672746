import { render, staticRenderFns } from "./industrialVision.vue?vue&type=template&id=6841b776&scoped=true"
import script from "./industrialVision.vue?vue&type=script&lang=js"
export * from "./industrialVision.vue?vue&type=script&lang=js"
import style0 from "./industrialVision.vue?vue&type=style&index=0&id=6841b776&prod&lang=scss&scoped=true"
import style1 from "./industrialVision.vue?vue&type=style&index=1&id=6841b776&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6841b776",
  null
  
)

export default component.exports