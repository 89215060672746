<template>
    <div class="aipage">
        <div class="banner-img background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/ai/banner.png')})`}">
            <div class="banner">
                <div class="banner-title background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/ai/title.png')})`}"></div>
                <div class="banner-smallTitle">中科逆熵工业视觉检测以打造智慧企业为目标，以多种人工智能算法和打数据处理为手段，依托“云边一体”的架构基础，结合人工智能、数字孪生等新一代信息技术，自上而下打通战略决策层到现场执行层的各个系统，提高生产效率，实现个性化定制、网络化协同、智能化制造、服务化延伸的新型制造管理模式，为制造企业数字化转型升级赋能。</div>
            </div>
        </div>
        <div class="aipage_wrap" style="padding-bottom: 0.6rem;">
            <div class="aipage_content">
                <p class="titleBlue">平台介绍</p>
                <div class="flex-center">
                    <div class="aipage-card" v-for="item in plateFormInfo" :key="item.index">
                        <div class="aipage-card-icon background" :style="{ 'backgroundImage' : `url(${ item.icon })`}"></div>
                        <div class="aipage-card-title">{{ item.title }}</div>
                        <div class="aipage-card-main">
                            <div class="aipage-card-label">{{ item.label1 }}</div>
                            <div class="aipage-card-content">{{ item.content1 }}</div>
                            <div class="aipage-card-label">{{ item.label2 }}</div>
                            <div class="aipage-card-content">{{ item.content2 }}</div>
                            <div class="aipage-card-label">{{ item.label3 }}</div>
                            <div class="aipage-card-content">{{ item.content3 }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="aipage_wrap background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/ai/useBg.jpg')})`, 'height':'4.8rem' }"></div>
        <div class="aipage_wrap" style="padding-bottom: 0.8rem;">
            <div class="aipage_content">
                <p class="titleBlue">客户收益</p>
                <div class="flex-center">
                    <div class="aipage-card2" v-for="item in incomeInfoList" :key="item.index">
                        <div class="aipage-card2-title"><div class="aipage-card2-icon background" :style="{ 'backgroundImage' : `url(${ item.icon })`}"></div>{{ item.title }}</div>
                        <div class="aipage-card2-main">
                            {{ item.content }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  /* eslint-disable */
  export default {
    data() {
      return {
        plateFormInfo:[
            { title:'客户端训练机', label1:'产品介绍', label2:'产品功能', label3:'面向客户', content1:'客户端训练机是为中大型企业研发的一款C/S架构的客户端产品，深度定制化应用产品；客户需要购买相关硬件和软件产品；', content2:'数据集离线标注和管理；模型训练和测试；SDK打包和部署；用户信息管理；', content3:'中大型企业用户; 传统机器视觉产品无法满足产品发展需要；对数据有着较高的安全保护意识；', icon:require('@/assets/images/ai/plateIcon1.png')},
            { title:'Web端训练机', label1:'产品介绍', label2:'产品功能', label3:'面向客户', content1:'web端训练机是B/S架构的一款应用，通过电脑浏览器即可完成工业视觉产品检测需求；', content2:'数据集在线管理和数据标注；企业及个人信息管理；模型训练和测试；SDK打包下载；', content3:'中小型企业用户；不需要考虑数据的安全性；', icon:require('@/assets/images/ai/plateIcon2.png')},
            { title:'运营平台', label1:'产品介绍', label2:'产品功能', label3:'面向客户', content1:'运营平台与saas和paas平台连接打通，可灵活调取智算中心运算服务，为客户端提供基础运算能力；运营平台为web客户端的所有数据和客户端部分数据存储和管理；', content2:'用户管理、数据管理、数据标注管理、项目管理、模型训练管理、SDK打包打包', content3:'公司运营人员', icon:require('@/assets/images/ai/plateIcon3.png')},
        ],
        incomeInfoList:[
            { title:'数字化转型升级', content:'有利于制造业的数字化转型升级，运用多种人工智能深度学习检测手段可大大减少机器对人的伤害，长远来看促进了生产力的变革，改善了劳动者的工作条件，提高了企业的数字化水平，为国家发展高端制造业建立了一个高技术力的新局面。', icon:require('@/assets/images/ai/1.png')},
            { title:'提供有力的信息保障', content:'制造业作为我国国民经济的支柱产业，是经济增长的主导部门和经济转型的基础，更是经济社会发展的重要依托。工业视觉检测智能处理解决方案面向大、中、小型各类制造业企业，能够提供面向工厂实际生产层面的关键应用，满足不同层次的检测需求。', icon:require('@/assets/images/ai/2.png')},
            { title:'提升业务稳定性', content:'采用边缘端算法识别，硬件架构更简单，更稳定，性价比高，能够显著降低效费比；更具有硬件快速部署，算法灵活交付的特点，缩短业务上线周期，提高产品质量管理效率。', icon:require('@/assets/images/ai/3.png')},
        ] 
      }
    },
    mounted() {
          document.body.scrollTop = document.documentElement.scrollTop = 0;
      },
    methods: {
     
    },
  }
  </script>
<style lang="scss" scoped>
.banner {
    left: 20%;
    top:2rem;
    &-title{
        width: 4.7rem;
        height: 1.4rem;
        background-size: contain;
    }
    &-smallTitle{
        color: #fff;
        width: 6.8rem;
        letter-spacing: 2px;
        line-height: 0.24rem;
        font-size: 0.14rem;
    }
}
.background{
    background-position: center;
    background-repeat: no-repeat;
}
.banner-img{
    width: 100%;
    height: 4rem;
    position: relative;
    background-size: cover;
}
.titleBlue{
    font-size: 0.3rem;
    color: #0061E3;
    font-weight: 600;
    letter-spacing: 0.08rem;
    text-align: center;
    height: 0.6rem;
    margin: 0.2rem 0;
}
.aipage-card{
    width: 3.8rem;
    height: 3rem;
    background: linear-gradient(90deg, #FFFFFF, #EBF3FF);
    padding: 0.2rem;
    z-index: 1;
    position: relative;
    &-icon{
        z-index: 10;
        width: 1.2rem;
        height: 1.3rem;
        background-size: cover;
        position: absolute;
        top:0.2rem;
        right: 0;
    }
    &-title{
        height: 0.18rem;
        line-height: 0.18rem;
        font-size: 0.16rem;
        border-left: 4px solid #0061E3;
        text-indent: 0.1rem;
        font-weight: 600;
        font-family: Source Han Sans CN;
        letter-spacing: 1px;
        margin-bottom: 0.1rem;
    }
    &-main{
        padding: 0.16rem;
        z-index: 100;
        position: relative;
    }
    &-label{
        font-size: 0.14rem;
        color: #333;
        font-weight: 600;
        height: 0.24rem;
    }
    &-content{
        font-size: 0.12rem;
        color: #333;
        margin-bottom: 0.3rem;
        letter-spacing: 1px;
    }
}
.aipage-card:nth-of-type(2){
    margin: 0 0.15rem;
}
.aipage-card2{
    width: 3.6rem;
    height: 2.8rem;
    z-index: 1;
    position: relative;
    &-title{
        width: calc(100% - 1.2rem);
        height: 0.42rem;
        line-height: 0.42rem;
        font-size: 0.18rem;
        color: #0061E3;
        font-weight: 600;
        padding: 0 0.6rem;
        background-color: #fff;
        border: 1px solid #fff;
        position: relative;
    }
    &-main{
        border: 1px solid #BBD6FF;
        width: calc(100% - 1rem);
        height: 2rem;
        padding:0.2rem 0.4rem 0.1rem 0.6rem;
        letter-spacing: 2px;
        font-size: 0.12rem;
        color: #333;
        line-height: 0.22rem;
    }
    &-icon{
        background-size: contain;
        width: 0.3rem;
        height: 0.32rem;
        position: absolute;
        left: 0.16rem;
        top:0.06rem;
    }
}
.aipage-card2:nth-of-type(2){
    margin: 0 0.5rem;
}
</style>
<style lang="scss">
.aipage_wrap{
    width: calc(100% - 0.48rem);
    display: flex;
    justify-content: center;
    padding: 0.24rem;
    background-color: #f5f5f5;
}
.aipage_content{
    width: 12rem;
}
.flex-center{
    display: flex;
    justify-content: center;
}

</style>
 